





















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import conveniosModule from "@/store/modules/convenio-module";
import { convenio } from "@/shared/dtos/convenio";
import { FormItem } from "@/components/DinamicallyForm/FormItem";
import { UtilsBoolean } from "@/utils/utils-boolean";
import { DynamicChangeEventClass } from "@/components/DinamicallyForm/DynamicChangeEventClass";

@Component({
  components: {},
})
export default class conveniosFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: convenio = new convenio();

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await conveniosModule.getconvenio(this.getparamId().Parametro);
      this.item_pantalla = conveniosModule.convenio;
    } else {
      this.item_pantalla.jornada_intensiva_inicio = new Date();
      this.item_pantalla.jornada_intensiva_fin = new Date();
      this.item_pantalla.jornada_intensiva = true;
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  SetJornada(event: DynamicChangeEventClass) {
    //this.item_pantalla.jornada_intensiva_inicio = new Date();
    //this.item_pantalla.jornada_intensiva_fin = new Date();
    if (UtilsBoolean.Anytoboolean(event.NewValue)) {
      this.SetFormItem("jornada_intensiva_inicio").data = new Date();
      this.SetFormItem("jornada_intensiva_fin").data = new Date();
    }
    this.SetFormItem("jornada_intensiva_inicio").isVisible(
      UtilsBoolean.Anytoboolean(event.NewValue)
    );
    this.SetFormItem("jornada_intensiva_fin").isVisible(
      UtilsBoolean.Anytoboolean(event.NewValue)
    );
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 10, 6);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.codigo,
      "Código",
      "codigo",
      50
    ).Dimesiones(12, 10, 6);
    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.horas_anuales,
      "Horas anuales",
      "horas_anuales"
    ).Dimesiones(12, 10, 6);
    this.AddFormItemDataDefaultBoolean(
      this.item_pantalla.jornada_intensiva,
      "Jornada intensiva",
      "jornada_intensiva",
      1,
      "Jornada intensiva",
      "Jornada intensiva"
    ).Dimesiones(12, 10, 6);
    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.jornada_intensiva_inicio),
      "Jornada intensiva inicio",
      "jornada_intensiva_inicio"
    )
      .Dimesiones(12, 10, 6);
    this.AddFormItemDataDefaultDate(
      new Date(this.item_pantalla.jornada_intensiva_fin),
      "Jornada intensiva fin",
      "jornada_intensiva_fin"
    )
      .Dimesiones(12, 10, 6);
    this.AddFormItemDataDefaultString(
      this.item_pantalla.notas,
      "Notas",
      "notas",
      60
    )
      .CreateTextArea()
      .Dimesiones(12, 12, 12);
  }

  public Insertar(object: convenio) {
    conveniosModule.guardarconvenio(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: convenio) {
    conveniosModule.modificarconvenio(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
